// React Component
import React, { useRef, useState } from "react"
import { Link, useParams } from "react-router-dom"

// App Component
import { NavLayout } from "../../../layouts"
import { Routes } from "../../../routes"
import { FamlaServices } from "../../../services"
import { useAppSelector } from "../../../stores"
import { ApprovalModal, ChangeLanguageModal, GenericModal, InviteUser, ShareModal, GenericModalParent, GrantEditPermissionModal } from "../../../views/modal"
import EditModal from "../../../views/modal/editModal"
// import { HeaderInterview } from "../../../views"
import HeaderInterview from "../../../views/interview/header"
import ChatBot from "../../../views/interview/chatBot"


//interface
import { iInterview, iCollaboration } from "../../../components/type"
import iMap from "../../../components/type/iMap"
import { useTranslation } from "react-i18next"
import { iAlert } from "../../../interfaces"

interface StateType {
    interviews: iInterview;
    collaborators: iCollaboration[];
    processName: string;
    procesId: string;
    interviewId: string;
    isLoading: boolean
    processTitle: string
    email: string,
    isSubmit: boolean,
    role: string,
    isLoadingProcess: boolean,
    autorisation: boolean,
    generateMap: string
    isloadingCollaborators: boolean
    loadSwim: boolean
    actionId: string,
    swimlaneName: string
}

const ProcessInterview = () => {
    const { id } = useParams();
    const { t } = useTranslation()
    const closeRef = useRef<HTMLButtonElement>(null);
    const users = useAppSelector((state) => state.auth);
    const inviteAproval = useRef<HTMLDivElement>(null)
    const shareRef = useRef<HTMLDivElement>(null)
    const editRef = useRef<HTMLDivElement>(null)
    const deleteDiagramRef = useRef<HTMLDivElement>(null)
    const deleteInterviewRef = useRef<HTMLDivElement>(null)
    const refEnableUser = useRef<HTMLDivElement>(null)
    const closeOffCanvas = useRef(null)
    const [invited, setInvited] = React.useState<string>();
    const [swimlanes, setSwimlanes] = useState<iMap[]>([]);
    const [interviewsData, setInterviewsData] = useState<iInterview[]>([]);
    const [collaborator, setCollaborator] = useState(users.user);
    const [contributeur, setContributeur] = useState(users.user);
    const [time, setTime] = useState(0);
    const [state, setState] = React.useState<StateType>({
        interviews: {} as iInterview,
        collaborators: [],
        processName: '',
        procesId: '',
        interviewId: "",
        isLoading: true,
        processTitle: "",
        email: "",
        isSubmit: false,
        role: "",
        isLoadingProcess: true,
        isloadingCollaborators: true,
        autorisation: true,
        generateMap: "",
        loadSwim: true,
        actionId: "",
        swimlaneName: ""
    })

    const [alert, setAlert] = React.useState<iAlert>({
        isActive: false,
        type: '',
        message: ''
    })

    const [interviewwId, setInterviewwId] = useState(id)
    const updateInterviewwId = (newId: string | undefined) => {
        setInterviewwId(newId);

    };

    const updateCollaborators = (col: any) => {
        setCollaborator(col);
    };

    const prepareAndShowModal = (col: any) => {
        setContributeur(col)
        refEnableUser && refEnableUser.current?.click()
    };

    const getRoleAuthorization = async (roleId: string): Promise<boolean> => {
        try {
            const endpoint = `/role/${state.procesId}/${roleId}`;
            const data = await FamlaServices("api_host", endpoint, "GET", "", users.access_token);
            if (data.body.status_code === 200) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            return false;
        }
    };


    React.useEffect(() => {

        const getAutorisationOfUser = async () => {
            let data = await FamlaServices("api_host", `/interview/${interviewwId}`, "GET", "", users.access_token);
            if (data.status === 200) {
                setState((prev) => ({ ...prev, autorisation: data.body.data }))
            }
        }

        getAutorisationOfUser();

        const loadTime = async (pId: string) => {
            let data = await FamlaServices("api_host", `/stats/process/${pId}`, "GET", "", users.access_token);
            if (data.status === 200) {
                setTime(data.body.data);
            }
        };

        const loadAllSwimlane = async (id: string) => {
            let data = await FamlaServices("api_host", `/mapping/process/${id}`, "GET", "", users.access_token);
            if (data.status === 200) {
                const datas = data.body.data;
                const dataReverse = datas.reverse();
                setState((prev) => ({ ...prev, loadSwim: false }))
                setSwimlanes(dataReverse);
            } else {
                setState((prev) => ({ ...prev, loadSwim: false }))
            }
            loadTime(id);
        };

        const getProcessByUser = async () => {
            let data = await FamlaServices("api_host", `/process/my`, "GET", "", users.access_token);
            if (data.status === 200) {
                setTimeout(() => {
                    setState((prev) => ({ ...prev, collaborators: data.body.data, isloadingCollaborators: false }))
                }, 500);
            }
        }
        getProcessByUser()

        const getRoleByprocess = async (processId: string) => {
            try {
                const data = await FamlaServices("api_host", `/process/my/sub/${processId}`, "GET", "", users.access_token);
                if (data.status === 200) {
                    const roles = data.body.data;
                    const updatedRoles = await Promise.all(
                        roles.map(async (role: any) => {
                            if (role.collaboration?.user) {
                                const editMode = await getRoleAuthorization(role.collaboration.user._id);
                                return {
                                    ...role,
                                    collaboration: {
                                        ...role.collaboration,
                                        user: {
                                            ...role.collaboration.user,
                                            editMode,
                                        },
                                    },
                                };
                            }
                            return role;
                        })
                    );

                    setState((prev) => ({ ...prev, isLoadingProcess: false }));
                    setInterviewsData(updatedRoles)

                } else {
                    setState((prev) => ({ ...prev, isLoadingProcess: false }));
                }
            } catch (error) {
                setState((prev) => ({ ...prev, isLoadingProcess: false }));
            }
        };


        const getInterviewById = async () => {
            let data = await FamlaServices("api_host", `/interview/by/id/${interviewwId}`, "GET", "", users.access_token);
            if (data.status === 200) {
                let processId = data.body.data?.collaboration.process?._id
                let owner = data.body.data?.collaboration.process?.owner
                updateNotificationProcess(processId);
                loadAllSwimlane(processId);
                setState((prev) => ({ ...prev, interviews: data.body.data, processTitle: owner.fullname, procesId: processId }))
                getRoleByprocess(processId)
            }
        }

        getInterviewById()

        const updateNotificationProcess = async (processId: string) => {
            FamlaServices("api_host", `/notification/${processId}`, "PUT", "", users.access_token);
        }

    }, [id, interviewwId, state.interviewId, state.procesId, users.access_token])


    const deleteInterviews = async (interviews_id: string) => {

        try {
            const data = await FamlaServices("api_host", `/interview/${interviews_id}`, "DELETE", "", users.access_token);
            if (data.body.status_code === 200) {
                // setAlert({ isActive: true, message: t('views.processInterview.sucessDelete'), type: 'success' })
                setInterviewsData((prev) => {
                    return prev.filter((interview: iInterview) => interview._id !== interviews_id);
                });
            } else if (data.body.status_code === 400) {
                setAlert({ isActive: true, message: t('views.processInterview.youCannotDelete'), type: 'warning' })
            }

        } catch (error) {
            setState(prev => {
                return { ...prev, isLoadingProcess: false };
            });
        }
        setTimeout(() => {
            setAlert({ ...alert, isActive: false, message: "" })
        }, 5000);


    };

    const deleteSwimlaneDiagram = async (swimlaneId: string) => {
        setSwimlanes((prev) => prev.filter((swimlane) => swimlane._id !== swimlaneId));
        try {
            await FamlaServices("api_host", `/mapping/${swimlaneId}`, "DELETE", "", users.access_token);
        } catch (error) {
            setState(prev => {
                return { ...prev, isLoadingProcess: false };
            });
        }
    };


    function setModalWithSwimlaneId(id: string, action: string) {
        setState((prev) => ({ ...prev, actionId: id, swimlaneName: action }))
        if (action === "aproval") {
            inviteAproval.current && inviteAproval.current.click();
        } else if (action === "share") {
            shareRef.current && shareRef.current.click()
        } else if (action === "delete") {
            deleteDiagramRef.current && deleteDiagramRef.current.click()
        } else if (action === "deleInterview") {
            deleteInterviewRef.current && deleteInterviewRef.current.click()
        } else {
            editRef.current && editRef.current.click()
        }
    }




    return (
        <>
            <div className="d-flex justify-content-between fixed-top h-100 newbg-black vw-100">

                <div className="m-none h-100" style={{ backgroundColor: "#1F1F21", width: "285px" }}>
                    <NavLayout
                        processes={interviewsData}
                        recentProcess={state.collaborators}
                        processName={state.processTitle}
                        updateInterviewwId={updateInterviewwId}
                        updateCollaborators={updateCollaborators}
                        interviwsId={id}
                        isLoadingProcess={state.isLoadingProcess}
                        isloadingCollaborators={state.isloadingCollaborators}
                        closeOffCanvas={''}
                        deleteInterviews={setModalWithSwimlaneId}
                        deleteSwimlaneDiagram={setModalWithSwimlaneId}
                        swimlanes={swimlanes}
                        processId={state.procesId}
                        loadDiagram={state.loadSwim}
                        setModalWithSwimlaneId={setModalWithSwimlaneId}
                        renameSwimlane={setModalWithSwimlaneId}
                        shareSwimlane={setModalWithSwimlaneId}
                        prepareAndShowModal={prepareAndShowModal}
                    />
                </div>

                <div className="container vh-100 newbg-black px-5" >
                    <HeaderInterview />
                    <ChatBot
                        time={time}
                        interviewwId={interviewwId}
                        id={id}
                        setInvited={setInvited}
                        swimlanes={swimlanes}
                        collaborator={collaborator}
                        procesId={state.procesId}
                        interviews={state.interviews}
                    />

                </div>
            </div>
            <div className="offcanvas offcanvas-start " id="offcanvas" aria-labelledby="offcanvasLabel" style={{ backgroundColor: "#1F1F21" }}>
                <div className="offcanvas-header">
                    <Link to={Routes.DASHBOARD} className="text-light fw-bold fs-5 text-decoration-none ">
                        <i className="fa-solid fa-arrow-left"></i>
                    </Link>
                    <button ref={closeOffCanvas} type="button" className="btn-close text-reset bg-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body" style={{ backgroundColor: "#1F1F21" }}>
                    <div className="w-100 " >
                        <NavLayout
                            processes={interviewsData}
                            recentProcess={state.collaborators}
                            processName={state.processTitle}
                            updateInterviewwId={updateInterviewwId}
                            updateCollaborators={updateCollaborators}
                            interviwsId={id}
                            isLoadingProcess={state.isLoadingProcess}
                            isloadingCollaborators={state.isloadingCollaborators}
                            closeOffCanvas={closeOffCanvas}
                            deleteInterviews={setModalWithSwimlaneId}
                            deleteSwimlaneDiagram={setModalWithSwimlaneId}
                            swimlanes={swimlanes}
                            processId={state.procesId}
                            loadDiagram={state.loadSwim}
                            setModalWithSwimlaneId={setModalWithSwimlaneId}
                            renameSwimlane={setModalWithSwimlaneId}
                            shareSwimlane={setModalWithSwimlaneId}
                            prepareAndShowModal={prepareAndShowModal}

                        />

                    </div>
                </div>
            </div>

            <span ref={inviteAproval} data-bs-target={`#approval${state.actionId}`} data-bs-toggle='modal'></span>
            <span ref={shareRef} data-bs-target={`#shareModal${state.actionId}`} data-bs-toggle='modal'></span>
            <span ref={editRef} data-bs-target={`#edit${state.actionId}`} data-bs-toggle='modal'></span>
            <span ref={deleteDiagramRef} data-bs-target={`#generic${state.actionId}`} data-bs-toggle='modal'></span>
            <span ref={deleteInterviewRef} data-bs-target={`#genericParent${state.interviewId}`} data-bs-toggle='modal'></span>
            <span ref={refEnableUser} data-bs-target={`#grantEditPermission${contributeur._id}`} data-bs-toggle='modal'></span>

            <InviteUser
                processId={state.procesId}
                closeRef={closeRef}
                interviewwId={interviewwId}
                invited={invited}
            />
            <ApprovalModal swimlaneId={state.actionId} />
            <EditModal
                swimlaneId={state.actionId}
                processId={state.procesId}
                setProject={setSwimlanes}
                processName={state.swimlaneName}
            />
            <ShareModal swimlaneId={state.actionId} processId={state.procesId} />
            <ChangeLanguageModal />

            <GenericModal
                sentence={t('views.processInterview.deleteDiagramme')}
                processId={state.actionId}
                title={t('navigation.deleteConfirmation')}
                action={(id: string) => { deleteSwimlaneDiagram(id) }}
                actionButton={t('words.delete')}
            />
            <GenericModalParent
                sentence={t('views.processInterview.deleteInterviewQuestion')}
                processId={state.interviewId}
                title={t('navigation.deleteConfirmation')}
                action={(id: string) => { deleteInterviews(state.actionId) }}
                actionButton={t('words.delete')}
                isActiveAlert={alert.isActive}
                alertMsg={alert.message}
                alertType={alert.type}
            />
            <GrantEditPermissionModal
                stackholder={contributeur}
                setInterviews={setInterviewsData}
                processId={state.procesId}
                interviews={interviewsData}
            />
        </>
    )
}

export default ProcessInterview