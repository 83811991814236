import React from "react";
import "../../assets/css/processAnimation.css"

const Process = () => {
    return (
        <div
            className="position-relative mx-auto mb-4"
            style={{ width: "400px", height: "150px" }}
        >
            <svg className="w-100 h-100" viewBox="0 0 400 150">
                {/* First Process Box */}
                <rect
                    className="fill-none"
                    x="40"
                    y="40"
                    width="120"
                    height="80"
                    stroke="#c7755c"
                    strokeWidth="2"
                    style={{
                        fill: "none",
                        strokeDasharray: "400",
                        strokeDashoffset: "400",
                        animation: "drawBox 2.5s ease-in-out infinite",
                    }}
                ></rect>

                {/* Arrow */}
                <path
                    d="M 160 80 L 240 80"
                    className="fill-none"
                    stroke="#c7755c"
                    strokeWidth="2"
                    markerEnd="url(#arrowhead)"
                    style={{
                        strokeDasharray: "160",
                        strokeDashoffset: "160",
                        animation: "drawArrow 2.5s ease-in-out infinite",
                        animationDelay: "0.8s",
                    }}
                ></path>

                {/* Arrow Head Definition */}
                <defs>
                    <marker
                        id="arrowhead"
                        markerWidth="8"
                        markerHeight="5"
                        refX="7"
                        refY="2.5"
                        orient="auto"
                    >
                        <polygon points="0 0, 8 2.5, 0 5" style={{ fill: "#c7755c" }} />
                    </marker>
                </defs>

                {/* Second Process Box */}
                <rect
                    className="fill-none"
                    x="240"
                    y="40"
                    width="120"
                    height="80"
                    stroke="#c7755c"
                    strokeWidth="2"
                    style={{
                        fill: "none",
                        strokeDasharray: "400",
                        strokeDashoffset: "400",
                        animation: "drawBox 2.5s ease-in-out infinite",
                        animationDelay: "1.6s",
                    }}
                ></rect>

                {/* Process Labels */}
                <text
                    x="100"
                    y="80"
                    className="text-white"
                    textAnchor="middle"
                    style={{
                        fill: "white",
                        fontSize: "0.75rem",
                        opacity: 0,
                        animation: "fadeIn 2.5s ease-in-out forwards",
                        animationDelay: "1s",
                    }}
                >
                   
                </text>
                <text
                    x="300"
                    y="80"
                    className="text-white"
                    textAnchor="middle"
                    style={{
                        fill: "white",
                        fontSize: "0.75rem",
                        opacity: 0,
                        animation: "fadeIn 2.5s ease-in-out forwards",
                        animationDelay: "2s",
                    }}
                >
                    
                </text>
            </svg>
        </div>
    );
};

export default Process;
