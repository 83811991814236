import React, { useState, useEffect } from 'react';

//App
import Spiner from '../Spiner';
import { FamlaServices } from '../../services';
import { useAppSelector } from '../../stores';
import { TFunction } from 'i18next';
import ProcessTableRow from './processTableRow'

//interface
interface ChildProps {
    isLoading: boolean;
    list: any;
    t: TFunction
    searchResults: any
    searchQuery: any
    searchLoad: any
}

const ProcessList = (props: ChildProps) => {
    const { isLoading, list, t, searchQuery, searchResults, searchLoad } = props;
    const users = useAppSelector((state) => state.auth);
    const [mapStatus, setMapStatus] = useState<{ [key: string]: boolean }>({});
    const [interviewTimes, setInterviewTimes] = useState<(string | null)[]>([]);
    const [editableData, setEditableData] = useState<{
        [key: string]: {
            next_revision_date: string;
            metrics: string;
            revision_triggers: string;
        }
    }>({});
    const [loadingProcesses, setLoadingProcesses] = useState<{ [key: string]: boolean }>({});
    const [modifiedProcessIds, setModifiedProcessIds] = useState<string[]>([]);


    useEffect(() => {
        const loadAllMaps = async () => {
            const status: { [key: string]: boolean } = {};
            for (const process of list) {
                status[process.Process._id] = await loadMap(process.Process._id);
            }
            setMapStatus(status);
        };

        if (!isLoading) {
            loadAllMaps();
        }

        const loadInterviewTimes = async () => {
            const times = await Promise.all(
                list.map(async (data: any) => {
                    const time = await loadTime(data.Process._id);
                    return time;
                })
            );
            setInterviewTimes(times);
        };

        loadInterviewTimes();

        if (!isLoading) {
            const initialData: { [key: string]: { next_revision_date: string; metrics: string; revision_triggers: string; } } = {};
            list.forEach((data: any) => {
                initialData[data.Process._id] = {
                    next_revision_date: data.Process?.next_revision_date || "",
                    metrics: data.Process?.metrics || "",
                    revision_triggers: data.Process?.revision_triggers || ""
                };
            });
            setEditableData(initialData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, list]);

    const loadMap = async (processId: string) => {
        try {
            let data = await FamlaServices("api_host", `/mapping/${processId}`, "GET", "", users.access_token);
            return data.status === 200;
        } catch (e) {
            return false;
        }
    };

    const loadTime = async (processId: string): Promise<any> => {
        let data = await FamlaServices("api_host", `/stats/process/${processId}`, "GET", "", users.access_token);
        if (data.status === 200) {
            return data.body.data;
        }
        return null;
    };

    const updateProcessData = async (processId: string) => {
        setLoadingProcesses(prev => ({ ...prev, [processId]: true }));
        try {
            const updatedData = editableData[processId];
            const response = await FamlaServices("api_host", `/process/update/info/${processId}`, "PUT", updatedData, users.access_token);
            if (response.status === 200) {
                setLoadingProcesses(prev => ({ ...prev, [processId]: false }));
            }
        } catch (error) {
        }
        setLoadingProcesses(prev => ({ ...prev, [processId]: false }));
    };

    const requestChange = async (processId: string) => {
        setLoadingProcesses(prev => ({ ...prev, [processId]: true }));
        // try {
        //     const updatedData = editableData[processId];
        //     const response = await FamlaServices("api_host", `/process/update/info/${processId}`, "PUT", updatedData, users.access_token);
        //     if (response.status === 200) {
        //         setLoadingProcesses(prev => ({ ...prev, [processId]: false }));
        //     }
        // } catch (error) {
        // }
        setLoadingProcesses(prev => ({ ...prev, [processId]: false }));
    };

    const handleChange = (processId: string, fieldName: string, value: string | Date) => {
        setEditableData((prevData) => {
            const currentProcessData = prevData[processId] || {};

            const updatedFieldValue = fieldName === 'next_revision_date' && value instanceof Date
                ? (value as Date).toISOString().replace('T', ' ').split('.')[0]
                : value;

            return {
                ...prevData,
                [processId]: {
                    ...currentProcessData,
                    [fieldName]: updatedFieldValue,
                },
            };
        });

        const tableElement = document.querySelector('.tableProcess') as HTMLElement;
        if (tableElement) {
            setTimeout(() => {
                tableElement.scrollLeft = tableElement.scrollWidth;
            }, 0);
        }
    };

    const handleDateChange = (processId: string, date: Date) => {
        handleChange(processId, 'next_revision_date', date);
    };

    const handleInputChange = (processId: string, fieldName: string, value: string) => {
        handleChange(processId, fieldName, value);
    };


    return (
        <>
            <table className="table mb-0 text-nowrap pt-0 w-100">
                <thead className="w-100 fix-sidebar mb-2 top-0 pt-0 w-auto">
                    <tr className='bg-teal'>
                        <th className="border-0 text-white bg-teal">{t('views.processByGroupView.processName')}</th>
                        <th className="border-0 text-white bg-teal">{t('views.processByGroupView.ownerName')}</th>
                        <th className="border-0 text-white bg-teal">{t('views.processByGroupView.lastModifiedDate')}</th>
                        <th className="border-0 text-white bg-teal">{t('views.processByGroupView.diagramLink')}</th>
                        <th className="border-0 text-white bg-teal">{t('views.processByGroupView.approvalName')}</th>
                        <th className="border-0 text-white bg-teal">{t('views.processByGroupView.approvalDate')}</th>
                        <th className="border-0 text-white bg-teal">{t('views.processByGroupView.interviewTime')}</th>
                        <th className="border-0 text-white bg-teal">{t('views.processByGroupView.nexRevisionDate')}</th>
                        <th className="border-0 text-white bg-teal">{t('views.processByGroupView.processMetrics')}</th>
                        <th className="border-0 text-white bg-teal">{t('views.processByGroupView.revisionTriggers')}</th>
                        <th className="border-0 bg-teal"></th>
                    </tr>
                </thead>
                {isLoading ?
                    (
                        <Spiner className='bg-black my-2 teal spinner-border-sm' classParent=' d-flex align-items-center justify-content-center w-100 ' />
                    ) : (
                        <tbody className='small' style={{ width: "100%" }} >
                            {list && list.length === 0 && !isLoading ? (
                                <tr className='bg-black text-white'>
                                    <th colSpan={11} className="text-start fs-5 bg-black text-white border-0">{t('views.processByGroupView.noProcess')}</th>
                                </tr>
                            ) : (

                                (searchQuery !== "" ?
                                    <>
                                        {searchResults.map((data: any, index: number) => {
                                            return (
                                                <ProcessTableRow
                                                    key={data._id}
                                                    data={data}
                                                    index={index}
                                                    interviewTime={interviewTimes[index]}
                                                    editableData={editableData}
                                                    mapStatus={mapStatus}
                                                    users={users.user}
                                                    handleDateChange={handleDateChange}
                                                    handleInputChange={handleInputChange}
                                                    requestChange={requestChange}
                                                    updateProcessData={updateProcessData}
                                                    loadingProcesses={loadingProcesses}
                                                />
                                            );
                                        })}
                                        {(searchResults.length === 0 && searchLoad) &&
                                            <tr className='bg-black text-white'>
                                                <th colSpan={11} className="text-start fs-5 bg-black text-white border-0">{t('views.processByGroupView.noProcess')}</th>
                                            </tr>
                                        }
                                    </>
                                    :
                                    <>
                                        {list.map((data: any, index: number) => {
                                            return (
                                                <ProcessTableRow
                                                    key={data._id}
                                                    data={data}
                                                    index={index}
                                                    interviewTime={interviewTimes[index]}
                                                    editableData={editableData}
                                                    mapStatus={mapStatus}
                                                    users={users.user}
                                                    handleDateChange={handleDateChange}
                                                    handleInputChange={handleInputChange}
                                                    requestChange={requestChange}
                                                    updateProcessData={updateProcessData}
                                                    loadingProcesses={loadingProcesses}
                                                />
                                            );
                                        })}
                                    </>
                                )
                            )}
                        </tbody>
                    )}
            </table>
        </>
    );
};

export default ProcessList;
