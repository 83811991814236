// React Component
import React, { CSSProperties } from 'react'
import { NavLink } from 'react-router-dom'

// App Component
import { Routes } from '../routes'
import { Buttons, ViewAllLink } from '../components'
import { useTranslation } from 'react-i18next'
//interface
interface ChildProps {
    children: React.ReactNode
    title: string
    arrowBack: boolean
    style: CSSProperties
    addClassName: string
    route?: string
    viewall?: boolean,
    action?: string,
    actionOnclick?: (event: React.MouseEvent<HTMLButtonElement>) => void

}

const ProcessLayout = (props: ChildProps) => {
    const { children, title, arrowBack, style, addClassName, route, viewall, action, actionOnclick } = props
    const { t } = useTranslation()
    return (
        <div className="position-relative mb-5" >
            <div className='overflow-hidden mt-3'>
                <div>
                    <div className='d-flex justify-content-between text-white fs-3'>

                        <h5 className='fw-bold'>
                            {arrowBack &&
                                <NavLink to={Routes.PROCESS_VIEW} className='me-2 text-decoration-none text-white'>
                                    <i className="bi bi-arrow-left fs-5"></i>
                                </NavLink>
                            } {title}
                        </h5>
                        {/* <ViewAllLink urlLink={Routes.MY_PROCESS} /> */}
                        {route && viewall && <NavLink className={'fs-6 text-decoration-none viewall '} to={route ?? ""}>{t('views.processView.viewAll')}</NavLink>}
                        {action && <Buttons isLoading={false} arrowVisible={false} name={action} onClick={actionOnclick ?? (() => { })} arrowColor='' btnClass='btn btn-secondary mb-1' />}
                    </div>
                    <div className={`mt-0 ${addClassName}`} style={style}>
                        {children}
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ProcessLayout