// Reac Component
import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from "react-i18next";

// App Component
import { Avatar } from "../../components";
import { } from "../../routes"
import { iUser } from "../../components/type";
import { formatDateTime } from "../../utils";
import { DemoteAdminToUserModal, GenericModal, PromoteUserToAdminModal } from "../../views/modal";
import { useAppSelector } from "../../stores";

interface FeaturesProps {
    loading: boolean
    featuresDatas: iUser[]
    context?: string
    deleteCompany?: (id?: string) => void
    listType?: 'USERS' | 'COLLABORATORS' | 'ADMIN' | 'OWNER'
    setUsers?: Dispatch<SetStateAction<iUser[]>> | undefined;
}

function UsersList(props: FeaturesProps) {
    const { loading, featuresDatas, deleteCompany, listType, setUsers } = props;
    const users = useAppSelector((state) => state.auth);
    const { t } = useTranslation()
    const companyId = users.session.company_id;
    return (
        <>
            <table className="table mb-0 text-nowrap table-sm table-dark text-secondary ">
                <thead className="table-theme border-0">
                    <tr>
                        {/* <th className="border-0"></th> */}
                        <th className="border-0 text-center bg-teal text-white">{t('words.name')}</th>
                        {listType === "USERS" &&
                            <th className="border-0 text-center bg-teal text-white">{t('words.role')}</th>
                        }
                        <th className="border-0 text-center bg-teal text-white">{t('words.email')}</th>
                        <th className="border-0 text-center bg-teal text-white">{t('dashboard.registrationDate')}</th>
                        <th className="border-0 text-center bg-teal text-white">{t('dashboard.lastConnectionDate')}</th>
                        <th className="border-0 text-center bg-teal text-white">{t('dashboard.monthlyTokenConsumed')}</th>
                        <th className="border-0 text-center bg-teal text-white"></th>
                        <th className="border-0 text-center bg-teal text-white"></th>
                        <th className="border-0 text-center bg-teal text-white"></th>
                    </tr>
                </thead>
                <tbody>
                    {loading &&
                        <tr>
                            <td colSpan={5} className="text-center">
                                <div className="spinner-border spinner-border-sm text-primary" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </td>
                        </tr>
                    }
                    {!loading && featuresDatas.length <= 0 &&
                        <tr>
                            <th colSpan={5} className="text-center">{t('navigation.noData')}</th>
                        </tr>
                    }
                    {!loading &&
                        featuresDatas.map((data) => (
                            <tr key={data._id}>
                                {/* <td className="align-middle border-top-0 fw-bold">
                                    <Avatar link={""} user={data} height={40} width={40} classSelector="rounded-pill text-dark" />
                                </td> */}
                                <td className="align-middle border-top-0 text-center small">
                                    {data.fullname}
                                </td>
                                {listType === "USERS" &&
                                    <td className={`align-middle border-top-0 text-center  small ${data.role === "admin" ? "text-primary" : "text-secondary"}`}>
                                        {data?.role}
                                    </td>
                                }

                                <td className="align-middle border-top-0 text-center small">
                                    {data.email}
                                </td>

                                <td className="align-middle border-top-0 text-center small">
                                    {formatDateTime(data.created_at)}
                                </td>

                                <td className="align-middle border-top-0 text-center  small">
                                    {formatDateTime(data?.last_connection ?? "")}
                                </td>
                                <td className="align-middle border-top-0 text-center  small">
                                    {data.total_tokens}
                                </td>
                                {/* <td className="text-muted align-middle text-end">
                                    <Link to={`${Routes.USERS_DETAILS}/${data._id}`} className="btn btn-dark btn-xs">
                                        More <i className="fe fe-more-horizontal ms-2"></i>
                                    </Link>
                                </td> */}


                                {data.role !== 'admin' ?
                                    <td className="text-muted align-middle text-end">
                                        <button className="btn btn-primary btn-sm border-0  btn-xs" data-bs-toggle="modal" data-bs-target={`#promoteUserToAdmin${data._id}`}>
                                            {t('words.promote')}
                                        </button>
                                    </td>
                                    :
                                    <td className="text-muted align-middle text-end">
                                        <button className="btn btn-primary btn-sm border-0  btn-xs" data-bs-toggle="modal" data-bs-target={`#demoteAdminToUser${data._id}`}>
                                            {t('words.demote')}
                                        </button>
                                    </td>
                                }
                                {listType !== 'ADMIN' &&
                                    <td className="text-muted align-middle text-end">
                                        <button className="btn btn-theme btn-sm bg-teal border-0  btn-xs" data-bs-toggle="modal" data-bs-target={`#generic${data._id}`}>
                                            {t('words.delete')}<i className="fa-solid fa-trash ms-2 text-white small"></i>
                                        </button>
                                    </td>
                                }

                                <td>
                                    <GenericModal
                                        sentence={t('dashboard.answerDelete')}
                                        actionButton={t('words.delete')}
                                        action={(id?: string) => deleteCompany?.(data._id)}
                                        title={t('dashboard.deleteUser')}
                                        processId={data._id}
                                        classNameSentence="text-theme"
                                    />
                                    <PromoteUserToAdminModal users={featuresDatas} setUsers={setUsers} targetUserId={data._id} companyId={companyId} />
                                    <DemoteAdminToUserModal users={featuresDatas} setUsers={setUsers} targetUserId={data._id} companyId={companyId} listType={listType} />

                                </td>


                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </>
    )
};

export default UsersList;