import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import InputForm from '../form/InputForm';
import Buttons from '../form/Buttons';
import { formatDateTime, slice } from '../../utils';
import { Routes } from '../../routes';
import { iUser } from '../type';
import { spawn } from 'child_process';

interface ProcessTableRowProps {
    data: any;
    index: number;
    interviewTime: string | null;
    editableData: any;
    mapStatus: any;
    users: iUser;
    handleDateChange: (processId: string, date: Date) => void;
    handleInputChange: (processId: string, field: string, value: string) => void;
    requestChange: (processId: string) => void;
    updateProcessData: (processId: string) => void;
    loadingProcesses: any;
}

const ProcessTableRow: React.FC<ProcessTableRowProps> = ({
    data,
    interviewTime,
    editableData,
    mapStatus,
    users,
    handleDateChange,
    handleInputChange,
    requestChange,
    updateProcessData,
    loadingProcesses
}) => {
    const approver = data.swimlane?.approver;

    const isSaveButtonVisible = (
        (editableData[data.Process._id]?.metrics?.trim() || '') !== (data.Process.metrics || '') ||
        (editableData[data.Process._id]?.revision_triggers?.trim() || '') !== (data.Process.revision_triggers || '') ||
        (editableData[data.Process._id]?.next_revision_date || '') !== (data.Process.next_revision_date || '')
    );

    return (
        <tr className="small ms-1 table-dark w-100 ps-1 fs-6" key={data._id}>
            <td className="align-middle border-top-0">{slice(data.Process?.title, 20)}</td>
            <td className="align-middle border-top-0">
                <span>{data.Process.owner.fullname}</span>
            </td>
            <td className="align-middle border-top-0">
                <span>{formatDateTime(data.Process.created_at)}</span>
            </td>
            <td className="align-middle border-top-0">
                {mapStatus[data.Process._id] && users._id === data.Process.owner._id ? (
                    <a href={`${Routes.MAPPING}${data.Process._id}/${data.swimlane?._id}`}>
                        {slice(data.Process.description, 20)}
                    </a>
                ) : (
                    <span>{slice(data.swimlane?.name, 20)}</span>
                )}
            </td>
            <td className="align-middle border-top-0">
                <span>{approver?.fullname ? approver.fullname : approver?.email}</span>
            </td>
            <td className="align-middle border-top-0">
                <span>{approver ? formatDateTime(data.swimlane?.updated_at) : ''}</span>
            </td>
            <td className="align-middle border-top-0">
                <span>
                    {interviewTime}
                    {interviewTime && ' Minutes'}
                </span>
            </td>
            <td className="align-middle border-top-0">
                <DatePicker
                    selected={
                        editableData[data.Process._id]?.next_revision_date
                            ? new Date(editableData[data.Process._id]?.next_revision_date)
                            : null
                    }
                    onChange={(date) => handleDateChange(data.Process._id, date as Date)}
                    showTimeSelect
                    dateFormat="yyyy-MM-dd HH:mm:ss"
                    className="bg-black text-light form-control"
                />
            </td>
            <td className="align-middle border-top-0">
                <InputForm
                    inputType="text"
                    inputLabel="Metrics"
                    inputName="metrics"
                    inputValue={editableData[data.Process._id]?.metrics || ''}
                    isFloatingLabel={true}
                    labelClass="text-theme"
                    onChange={(e) =>
                        handleInputChange(data.Process._id, 'metrics', (e.target as HTMLInputElement).value)
                    }
                    inputContainerClass={''}
                    simpleInput={true}
                    inputClass="bg-black text-light"
                />
            </td>
            <td className="align-middle border-top-0">
                <InputForm
                    inputType="text"
                    inputLabel="Revision Triggers"
                    inputName="revision_triggers"
                    inputValue={editableData[data.Process._id]?.revision_triggers || ''}
                    isFloatingLabel={true}
                    labelClass="text-theme"
                    onChange={(e) =>
                        handleInputChange(data.Process._id, 'revision_triggers', (e.target as HTMLInputElement).value)
                    }
                    inputContainerClass={''}
                    simpleInput={true}
                    inputClass="bg-black text-light"
                />
            </td>
            <td className="align-middle border-top-0 text-center">
                {users._id === data.Process.owner._id && isSaveButtonVisible ? (
                    <Buttons
                        buttonType="submit"
                        name={!loadingProcesses[data.Process._id] ? 'Save' : ''}
                        onClick={() => updateProcessData(data.Process._id)}
                        isLoading={false}
                        btnClass="btn-theme bg-teal border-0 btn-sm"
                        labelClass="text-light fw-bold"
                        styleProps={{}}
                        withIcon={loadingProcesses[data.Process._id]}
                        iconName="fa-solid fa-spinner text-white bg-teal"
                        btnStyle={{ height: 58 }}
                        arrowVisible={false}
                        arrowColor=""
                    />
                ) : isSaveButtonVisible ? (
                    <Buttons
                        buttonType="submit"
                        name={!loadingProcesses[data.Process._id] ? 'Request Change' : ''}
                        onClick={() => requestChange(data.Process._id)}
                        isLoading={false}
                        btnClass="btn-theme bg-teal border-0  btn-sm"
                        labelClass="text-light fw-bold"
                        styleProps={{}}
                        withIcon={loadingProcesses[data.Process._id]}
                        iconName="fa-solid fa-spinner text-white bg-teal"
                        btnStyle={{ height: 58 }}
                        arrowVisible={false}
                        arrowColor=""
                    />
                ) : (
                    <button className='btn btn-transparent'></button>
                )}
            </td>
        </tr>
    );
};

export default ProcessTableRow;

