import React from 'react';
import '../../assets/css/MicAnimation.css';

interface chilprops {
    onClick: () => void
}

const RecordingMic = (props: chilprops) => {
    const { onClick } = props
    return (
        // <button onClick={onClick} className="position-fixed end-0 text-white text-decoration-none border-0 bg-transparent" style={{ marginBottom: '-4px', marginRight: "60px" }}>
        //     <div className="ripple-container recording" style={{ width: 50, height: 50 }}>
        //         <div className="ripple-container">
        //             <div className="confetti"></div>
        //             <div className="confetti"></div>
        //             <div className="confetti"></div>
        //             <div className="confetti"></div>
        //             <div className="confetti"></div>
        //         </div>
        //     </div>
        // </button>
        <button
            onClick={onClick}
            className="position-fixed end-0 text-white text-decoration-none border-0 bg-transparent"
            style={{ marginBottom: '-4px', marginRight: '60px' }}
        >
            <div
                className="mic-container recording"
                style={{ width: 50, height: 50 }}
            >
                <span className="circle"></span>
                <span className="circle"></span>
                <span className="circle"></span>
                <i className="bi bi-mic text-gradient"></i>
            </div>
        </button>

    );
};

export default RecordingMic;
