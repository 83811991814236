
//React
import React, { useRef, useState } from 'react'
import { useTranslation } from "react-i18next"

//App
import { Alert, Spiner, TextAreaForm } from "../../components"
import { FamlaServices } from '../../services';
import { useAppSelector } from '../../stores';
import { BASE_URL_AUDIO } from '../../services';

import Area from "./area"
import ContentQuestion from "./contentQuestion"
import Question from "./question"
import { ButtonGroup } from '../../components';
import { iAlert } from '../../interfaces';
import FileUpload from './FileUpload'

//Interface
import { iInterview, iInterviewContent, iUser } from '../../components/type'
import { useNavigate } from 'react-router-dom';

interface StateType {
    questionAndResponse: any[];
    interviewId: string;
    answer: string;
    isLoading: boolean
    processTitle: string
    email: string,
    isSubmit: boolean,
    role: string,
    isLoadingProcess: boolean,
    autorisation: boolean,

}

interface chilprops {
    time: Number
    interviewwId: string | undefined
    id: string | undefined
    setInvited: (invited: string | undefined) => void
    swimlanes: Array<any>
    collaborator: iUser
    procesId: string
    interviews: iInterview
}


const ChatBot = (props: chilprops) => {
    const { time, interviewwId, id, setInvited, swimlanes, collaborator, procesId, interviews } = props
    const { t } = useTranslation()
    const refchat = useRef<HTMLDivElement>(null)
    const [idLastAnwser, setIdLastAnwser] = React.useState("")
    const [isInputBlocked, setIsInputBlocked] = useState(false);
    const users = useAppSelector((state) => state.auth);
    const [spinner, setSpinner] = React.useState(false)
    const [currentIndex, setCurrentIndex] = React.useState<number>(0);
    const [lastReadAnswerId, setLastReadAnswerId] = React.useState<string | null>(null);
    const role = useAppSelector(state => state.role.role)
    const [file, setFile] = useState<File | null>(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isAudioFinished, setIsAudioFinished] = useState(false);
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const navigate = useNavigate()
    const [state, setState] = React.useState<StateType>({
        questionAndResponse: [],
        interviewId: "",
        answer: "",
        isLoading: true,
        processTitle: "",
        email: "",
        isSubmit: false,
        role: "",
        isLoadingProcess: true,
        autorisation: true,
    })

    const [thumbs, setThumbs] = useState({
        up: false,
        down: false,
        comment: "",
        submit: false,
    })

    const [alert, setAlert] = useState<iAlert>({
        isActive: false,
        type: "",
        message: ""
    })
    const lastQuestion = state.questionAndResponse[state.questionAndResponse.length - 1];
    const lastContent = state.questionAndResponse.length - 1;

    const endReply = t('views.processInterview.reply1')
    const endReply0 = t('views.processInterview.reply2')
    const other = t('views.processInterview.reply3')
    const referenceQuestion = t('views.processInterview.reply4')
    const referenceQuestion1 = t('views.processInterview.reply5')

    React.useEffect(() => {
        const getMyInterviewByProcess = async () => {
            setState((prev) => ({ ...prev, isLoading: true }))
            try {
                let newData = await FamlaServices("api_host", `/answer/${interviewwId}`, "GET", "", users.access_token);
                if (newData.status === 200) {
                    const dataQuestionAndResponse = newData.body.data
                    const reverveData = dataQuestionAndResponse.reverse()
                    setState((prev) => ({ ...prev, questionAndResponse: reverveData, isLoading: false }))
                }

                setState((prev) => ({ ...prev, isLoading: false }))
            } catch (error) {
                setState((prev) => ({ ...prev, isLoading: false }))
            }
        }

        getMyInterviewByProcess()

        const getAutorisationOfUser = async () => {
            try {
                let data = await FamlaServices("api_host", `/interview/${interviewwId}`, "GET", "", users.access_token);
                if (data.status === 200) {
                    setState((prev) => ({ ...prev, autorisation: data.body.data }))

                }
            } catch (error) {

            }
        }
        getAutorisationOfUser();

    }, [id, interviewwId, state.interviewId, procesId, users.access_token])

    React.useEffect(() => {
        // if (
        //     state.questionAndResponse.length > 0 &&
        //     currentIndex === state.questionAndResponse.length - 1
        // ) {
        //     const currentAnswer = state.questionAndResponse[currentIndex];
        //     if (currentAnswer && currentAnswer._id !== lastReadAnswerId) {
        //         handleSynthesize(currentAnswer._id);
        //         setLastReadAnswerId(currentAnswer._id);
        //     }
        // }

        if (audioRef.current) {
            audioRef.current.currentTime = 0;
            audioRef.current = null
        }

    }, [state.questionAndResponse.length, currentIndex, interviewwId])

    React.useEffect(() => {
        if (lastQuestion && lastQuestion.thumbs) {
            setThumbs({
                up: lastQuestion.thumbs.up === true,
                down: false,
                comment: "",
                submit: false,
            });
        }
    }, [lastQuestion]);

    React.useEffect(() => {
        if (refchat.current) {
            refchat.current.scrollTop = refchat.current.scrollHeight;
        }
        if (state.questionAndResponse?.length > 0) {
            setIdLastAnwser(state.questionAndResponse[state.questionAndResponse.length - 1]?._id);
        }

        let shouldBlockInput = false;
        state.questionAndResponse.forEach((question, index) => {
            if (
                (
                    (question.step[0] === 1 && question.step[1] === 4 && question.question_text !== referenceQuestion) ||
                    (question.step[0] === 3 && question.step[1] === 5 && question.question_text !== referenceQuestion) ||
                    question.question_text === referenceQuestion1
                ) && index === state.questionAndResponse.length - 1
            ) {
                shouldBlockInput = true;
            } else if (
                (question.step[0] === 1 && question.step[1] === 3 && index === state.questionAndResponse.length - 1) ||
                (question.step[0] === 1 && question.step[1] === 5 && index === state.questionAndResponse.length - 1)
            ) {
                shouldBlockInput = true;
            }

            if (question.step[0] === 1 && question.step[1] === 8) {
                // window.location.assign(`/process/interviews/${interviewwId}`);
                navigate(`/process/interviews/${interviewwId}`);
            }

        });

        setIsInputBlocked(shouldBlockInput);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.questionAndResponse, state.answer, id, spinner, currentIndex, thumbs.down, alert.isActive]);

    async function uploadFileToAnalyze(interviewId: string, processId: string) {
        try {
            if (!file) {
                console.error("Aucun fichier n'a été fourni.");
                return;
            }
            if (file.type === "application/pdf") {
                const endpoint = `/file/analyze-pdf?interview_id=${interviewId}&process_id=${processId}`;
                const formData = new FormData();
                file && formData.append("file", file);

                const data = await FamlaServices("api_host", endpoint, "POST", formData, "");

                if (data.status === 201) {
                    console.log("Fichier PDF traité avec succès.");
                    setFile(null);
                }
            } else {
                const endpoint = `/file/?interview_id=${interviewId}`;
                const formData = new FormData();
                file && formData.append("fileobject", file);

                const data = await FamlaServices("api_host", endpoint, "POST", formData, "");

                if (data.status === 201) {
                    setFile(null);
                }
            }
        } catch (error) {

        }
    }


    const sendAnswer = async (e: React.MouseEvent<HTMLButtonElement>, answerId: string | undefined, answerA: string, answer_id: string) => {
        setSpinner(true)
        const updateAnswerOfLastElement = [...state.questionAndResponse];
        const lastItemIndex = updateAnswerOfLastElement.length - 1;
        if (lastItemIndex >= 0) {
            updateAnswerOfLastElement[lastItemIndex] = { ...updateAnswerOfLastElement[lastItemIndex], content: answerA };
        }
        setState((prevState) => ({ ...prevState, questionAndResponse: updateAnswerOfLastElement, answer: "", }));

        for (let question of updateAnswerOfLastElement) {
            if (question.step[0] === 1 && question.step[1] === 5) {
                setInvited(question.invited)
            }
        }
        const answer = {
            content: answerA,
            answer_id: answer_id
        };
        if (file) {
            uploadFileToAnalyze(interviewwId ?? "", procesId)
        }

        let data = await FamlaServices("api_host", `/answer/${answerId}`, "PUT", answer, users.access_token);
        if (data.status === 200) {
            const newQuestionAndResponse = { ...data.body.data };
            const updatedListWithNewQuestionAndResponse = [...updateAnswerOfLastElement, newQuestionAndResponse];
            setState((prevState) => ({
                ...prevState,
                questionAndResponse: updatedListWithNewQuestionAndResponse,
                interviewId: state.interviewId,
                answer: "",
            }));


        } else if (data.status === 426) {
            setAlert({ isActive: true, type: 'warning', message: t('views.processInterview.requestLimit') })
            setIsInputBlocked(true)
        }
        setSpinner(false)
        setThumbs({ ...thumbs, up: false, down: false })
    };

    const updateContent = async (
        answerId: string,
        content: string,
    ) => {
        try {

            setState((prevState) => {
                const updatedQuestions = prevState.questionAndResponse.map((question) =>
                    question._id === answerId
                        ? { ...question, content: content }
                        : question
                );
                return {
                    ...prevState,
                    questionAndResponse: updatedQuestions,
                };
            });

            const answerData = {
                content: content,
                answer_id: answerId,
            };

            const data = await FamlaServices("api_host", `/answer/content/update`, "PUT", answerData, users.access_token);

            if (data.status === 200) {
                // const newQuestionAndResponse = { ...data.body.data };
                // setState((prevState) => {
                //     const updatedQuestions = [...prevState.questionAndResponse];
                //     if (updatedQuestions.length > 0) {
                //         updatedQuestions[updatedQuestions.length - 1] = newQuestionAndResponse;
                //     }
                //     return {
                //         ...prevState,
                //         questionAndResponse: updatedQuestions,
                //         interviewId: prevState.interviewId,
                //         answer: "",
                //     };
                // });

                if (file) {
                    uploadFileToAnalyze(interviewwId ?? "", procesId)
                }

            } else if (data.status === 426) {
                setAlert({
                    isActive: true,
                    type: 'warning',
                    message: t('views.processInterview.requestLimit'),
                });
                setIsInputBlocked(true)
            } else {
                console.error('Erreur de mise à jour de la réponse', data);
            }
        } catch (error) {
            console.error('Erreur de requête', error);
        } finally {
            setSpinner(false);
        }
    };


    const updateAnswerWithUpDown = async (upDown: string, answerId: string) => {
        setThumbs(prevThumbs => ({ ...prevThumbs, submit: true, up: upDown === 'up', down: upDown === 'down' }));

        const form = {
            up: upDown === 'up',
            comment: thumbs.comment
        };

        try {
            const data = await FamlaServices("api_host", `/answer/thumbs/${answerId}`, "PUT", form, users.access_token);

            if (data.status === 200) {
                setThumbs({
                    up: upDown === 'up',
                    down: upDown === 'down',
                    comment: "",
                    submit: false,
                });
            } else {
                setThumbs(prevThumbs => ({ ...prevThumbs, submit: false }));
            }
        } catch (error) {
            setThumbs(prevThumbs => ({ ...prevThumbs, submit: false }));
        }
    }

    const shouldDisplayActionButtons = (question: iInterviewContent, index: number, totalQuestions: number,): boolean => {
        const isFinalQuestion = index === totalQuestions - 1;
        const isSpecificStepMatch =
            (question.step[0] === 1 && question.step[1] === 4 && question.question_text !== referenceQuestion) ||
            (question.step[0] === 3 && question.step[1] === 5 && question.question_text !== referenceQuestion) ||
            question.question_text === referenceQuestion1;

        const isAlternateStepMatch = question.step[0] === 1 && question.step[1] === 3;
        return (isSpecificStepMatch || isAlternateStepMatch) && isFinalQuestion;
    };


    function setThumbsUpDown(upDown: string) {
        setThumbs(prevThumbs => ({
            ...prevThumbs,
            up: upDown === 'up',
            down: upDown === 'down'
        }));
    }

    const handleChange = (event: any) => {
        const { type, name, value, checked } = event.target;
        let fieldValue = type === 'checkbox' ? checked : value;

        if (type === 'number') {
            fieldValue = Number(fieldValue);
        }
        if (name === 'area') {
            setState({ ...state, answer: fieldValue })
        }
        if (name === 'area1') {
            setThumbs({ ...thumbs, comment: fieldValue })
        }
        if (type === 'number') {
            fieldValue = Number(fieldValue);
        }
        if (name === 'email') {
            setState((prev) => ({ ...prev, email: fieldValue }))
        } else if (name === 'role') {
            setState({ ...state, role: fieldValue })
        }
    };

    function tag() {
        const lastQuestion = state.questionAndResponse[state.questionAndResponse.length - 1]?.question_text;
        return lastQuestion !== endReply0 && lastQuestion !== endReply
    }

    const updateAnswer = (newAnswer: string) => {
        setState((prevState) => ({
            ...prevState,
            answer: newAnswer,
        }));
    };

    const handleFileSelect = (selectedFile: File) => {
        setFile(selectedFile);
    };



    const handleSynthesize = async (answerId: string) => {
        try {

            if (audioRef.current) {
                // Si l'audio est déjà en cours de lecture et que l'utilisateur veut l'arrêter
                if (isPlaying && !audioRef.current.paused) {
                    audioRef.current.pause(); // Mettre l'audio en pause
                    setIsPlaying(false);
                    return;
                }

                // Si l'audio est en pause, le reprendre
                if (audioRef.current.paused) {
                    audioRef.current.play().catch(() => {
                        console.error("Failed to resume audio");
                    });
                    setIsPlaying(true);
                    return;
                }
            }


            setIsPlaying(true);
            setIsAudioFinished(false);

            // Récupérer le nouvel audio si nécessaire
            const response = await FamlaServices("api_host", `/audio/${answerId}`, "GET", "", users.access_token);

            if (response.status === 200) {
                const audioSrc = `${BASE_URL_AUDIO.url}${response.body.data}`;

                // Si un audio existant est déjà en lecture, on ne crée pas un nouveau fichier audio
                if (!audioRef.current || audioRef.current.src !== audioSrc) {
                    const audio = new Audio(audioSrc);
                    audioRef.current = audio;

                    // Lecture du nouvel audio
                    audio.play().catch(() => {
                        console.error("Failed to play audio");
                    });

                    // Gérer la fin de l'audio
                    audio.onended = () => {
                        setIsPlaying(false);
                        setIsAudioFinished(true);
                    };
                }
            }
        } catch (error) {
            console.error("Error synthesizing text:", error);
            setIsPlaying(false);
            setIsAudioFinished(false);
        }
    };




    return (
        <div className="w-75 w-sm-100 m-auto position-relative">
            <div className="overflow-auto custom-vh pb-5 px-3" ref={refchat}>
                {state.isLoading ?
                    < Spiner className="d-flex justify-content-center spinner-border-sm " />
                    :
                    <>
                        {state.questionAndResponse.map((question: iInterviewContent, index: number) => {

                            return (
                                <div key={question._id}>
                                    <div className="mt-4">
                                        <Question
                                            key={index}
                                            index={index}
                                            currentIndex={currentIndex}
                                            questionText={question.question_text}
                                            questionAndResponseLength={state.questionAndResponse.length}
                                            setCurrentIndex={setCurrentIndex}
                                        />

                                        {shouldDisplayActionButtons(question, index, state.questionAndResponse.length) &&
                                            <ButtonGroup
                                                buttons={[
                                                    {
                                                        text: t('words.yes'),
                                                        onClick: (e) => sendAnswer(e, interviewwId, t('words.yes'), question._id)
                                                    },
                                                    {
                                                        text: t('words.no'),
                                                        onClick: (e) => sendAnswer(e, interviewwId, t('words.no'), question._id)
                                                    }
                                                ]}
                                                className="ps-4 mt-3"
                                                ariaLabel="Basic radio toggle button group"
                                            />
                                        }
                                        {(question.step[0] === 1 && question.step[1] === 5 && index === state.questionAndResponse.length - 1) &&
                                            <ButtonGroup
                                                buttons={[
                                                    {
                                                        text: t('words.yes'),
                                                        onClick: (e) => sendAnswer(e, interviewwId, t('words.yes'), question._id),
                                                        dataBsTarget: '#inviteUser',
                                                        dataBsToggle: 'modal'
                                                    },
                                                    {
                                                        text: t('words.no'),
                                                        onClick: (e) => sendAnswer(e, interviewwId, t('words.no'), question._id)
                                                    }
                                                ]}
                                                className="ps-4 mt-3"
                                                ariaLabel="Basic radio toggle button group"
                                            />

                                        }
                                        {index === state.questionAndResponse.length - 1 && question.question_text !== endReply0 && question.question_text !== other && question.question_text !== endReply && (
                                            <ButtonGroup
                                                buttons={[
                                                    {
                                                        text: '',
                                                        onClick: () => updateAnswerWithUpDown('up', question._id),
                                                        icon: thumbs.up ? <i className="fa-solid fa-thumbs-up"></i> : <i className="fa-regular fa-thumbs-up"></i>,
                                                        isSelected: thumbs.up
                                                    },
                                                    {
                                                        text: '',
                                                        onClick: () => setThumbsUpDown('down'),
                                                        icon: thumbs.down ? <i className="fa-solid fa-thumbs-down"></i> : <i className="fa-regular fa-thumbs-down"></i>,
                                                        isSelected: thumbs.down
                                                    }
                                                ]}
                                                className="ps-4 justify-content-end mt-3"
                                                ariaLabel="Basic radio toggle button group"
                                            />

                                        )}
                                        {index === state.questionAndResponse.length - 1 && (
                                            <ButtonGroup
                                                buttons={[
                                                    {
                                                        text: '',
                                                        onClick: () => handleSynthesize(question._id),
                                                        icon: isPlaying ? <i className="fa-solid fa-volume-xmark animate-pulse-bootstrap text-white"></i> : <i className="fa-solid fa-volume-high"></i>,
                                                        isSelected: thumbs.up
                                                    },
                                                ]}
                                                className="ps-4 justify-content-end mt-3"
                                                ariaLabel="Basic radio toggle button group"
                                            />

                                        )}
                                        {(thumbs.down && index === state.questionAndResponse.length - 1) && (
                                            <TextAreaForm
                                                name="area1"
                                                sendButton={thumbs.comment.trim().length > 0}
                                                label=""
                                                rows={1}
                                                classNameArea="w-100 bg-transparent text-light text-bg-dark py-2 px-3 rounded-2 pe-5 border-0 mb-2"
                                                classNameContainer="custom_width_area message-box rounded-4 mt-3"
                                                placeholder={t('views.processInterview.comment') + '...'}
                                                onChange={(e) => handleChange(e)}
                                                value={thumbs.comment}
                                                interviewId={state.interviewId}
                                                sendAnswer={() => updateAnswerWithUpDown("down", question._id)}
                                                spinner={thumbs.submit}
                                                maxRows={4}
                                                minRows={3}
                                                button={true}
                                                readOnly={false}
                                                updateAnswer={() => { }}
                                                recognition={false}
                                            />
                                        )}


                                    </div>

                                    {(question.step[0] === 1 && question.step[1] === 2) && state.questionAndResponse.length < 3 &&
                                        <FileUpload onFileSelect={handleFileSelect} />
                                    }

                                    {(question.content?.length > 0 || question.content !== null) &&
                                        <ContentQuestion
                                            fullname={collaborator.fullname}
                                            questionData={question}
                                            lastContent={lastContent}
                                            file={file}
                                            fileIn={interviews?.file_in}
                                            index={index}
                                            onFileSelect={handleFileSelect}
                                            updateContent={updateContent}
                                        />
                                    }

                                    {(alert.isActive && index === state.questionAndResponse.length - 1) &&
                                        <Alert type={alert.type} msg={alert.message} clasName='mt-3' />
                                    }

                                </div>
                            )
                        }


                        )}


                    </>
                }

            </div>

            <div className='w-100 custom_parent_area'>
                <Area
                    state={state}
                    handleChange={handleChange}
                    sendAnswer={sendAnswer}
                    interviewwId={interviewwId}
                    idLastAnwser={idLastAnwser}
                    tag={tag}
                    t={t}
                    spinner={spinner}
                    isInputBlocked={isInputBlocked}
                    swimlanes={swimlanes}
                    role={role}
                    time={time}
                    updateAnswer={updateAnswer}
                    recognition={true}
                    file={file}
                    isAudioFinished={isAudioFinished}
                />
            </div>


        </div >
    )
}

export default ChatBot