import React from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'


import Dropdown from '../Dropdown'
import { AssignProcessToFolderModal, EditprocessModal, GenericModal, PublishModal } from '../../views/modal'
import { useAppSelector } from '../../stores'
import { FamlaServices } from '../../services'
import { slice } from '../../utils'
import { Routes } from '../../routes'
import { iProject } from '../../interfaces'
import { iProcess } from '../type'
import { Images } from '../../constants'

interface ChildProps {
    processName: string
    thisProcessHasABadge: boolean
    theBadgeHasAValue: boolean
    badgeValue: number
    owner: string
    thisProcessHasAOwner: boolean
    key: number | string
    route: string
    deleteProcess: () => void
    description: string
    processId: string
    folder: boolean
    setProject: React.Dispatch<React.SetStateAction<iProject[]>>;
    setProcess: React.Dispatch<React.SetStateAction<iProcess[]>>;
    usage?: string
    index?: number
    elementType?: "process" | "folder" | "popular"
}

const CardProcess = (props: ChildProps) => {
    let {
        setProject,
        setProcess,
        processId,
        processName,
        thisProcessHasABadge,
        theBadgeHasAValue,
        badgeValue,
        owner,
        thisProcessHasAOwner,
        key,
        route,
        description,
        deleteProcess,
        folder,
        usage,
        index,
        elementType

    } = props

    const { t } = useTranslation();
    const role = useAppSelector(state => state.role.role)
    const users = useAppSelector((state) => state.auth);
    const [interviewId, setInterviewId] = React.useState<string | undefined>()
    const firstindex = 0

    function descriptionSize() {
        if (elementType === 'process' || elementType === "folder") {
            return 150
        } else {
            return 160
        }
    }

    React.useEffect(() => {
        const getMyInterviewByProcess = async () => {
            let data = await FamlaServices("api_host", `/interview/process/${processId}`, "GET", "", users.access_token);
            if (data.status === 200) {
                setInterviewId(data.body.data._id);
            }
        }
        if (thisProcessHasAOwner) {
            getMyInterviewByProcess()
        }

    }, [processId, thisProcessHasAOwner, users.access_token])

    function routes(): string {
        let newroute: any
        if (thisProcessHasAOwner && !folder && interviewId) {
            newroute = `${(route + interviewId)}`
        } else if (folder) {
            newroute = `${(Routes.PROCESS_BY_FOLDER + processId)}`
        }
        return newroute
    }

    const handleCardClick = () => {
        if (usage === "populare") {
            window.location.href = Routes.PROCESS_CREATE;
        } else {
            window.location.href = routes();
        }

    }

    const handleDropdownClick = (event: React.MouseEvent) => {
        event.stopPropagation();
    }


    const checkBackground = () => {
        if (elementType === "process") {
            return `url(${Images.process})`
        } else if (elementType === "folder") {
            return `url(${Images.folder})`
        } else {
            return ``
        }

    }

    const checkDefault = (name: string) => {
        if (name === "Default") {
            return t('views.processView.defaultFolder')
        } else {
            return name
        }
    }



    return (
        <>
            <div
                className='mt-3'
                key={key}
                style={{ cursor: 'pointer' }}
                onClick={handleCardClick}
            >
                <div className={`cardProcess text-white text-decoration-none card-bg `} style={{ backgroundImage: checkBackground(), backgroundSize: 'cover' }}>
                    <div className='w-100 px-3 py-3'>
                        <div className='d-flex justify-content-between'>
                            <NavLink to={routes()} className={`text-decoration-none  `}>
                                <span className={` fw-bold ${elementType === "popular" ? 'text-gradient' : "text-white"}`}>{slice(checkDefault(processName), 35)}</span>
                                {thisProcessHasABadge &&
                                    <span className={`badge rounded-pill bg-primary border border-0 ms-2`}>
                                        {theBadgeHasAValue && badgeValue}
                                    </span>
                                }
                            </NavLink>
                            {
                                <Dropdown
                                    classMenu='position-absolute'
                                    dropName=''
                                    dropdownType='dropdown dropstart '
                                    iconClassName={((role !== 'WORKER' && processName !== "Default") && thisProcessHasAOwner) ? 'fa fa-ellipsis-h' : ""}
                                    buttonDropClassName={`${((role !== 'WORKER' && processName !== "Default") && thisProcessHasAOwner) ? 'btn btn-secondary bg-transparent border-0 ' : 'btn btn-transparent disabled border-0 text-black-custom '}`}
                                    styleButton={{}}
                                    action3={folder ? '' : `${t('words.organize')}`}
                                    theRouteOfAction4=''
                                    deleteProcess={deleteProcess}
                                    action1=''
                                    theRouteOfAction5=''
                                    dataBsTarget={`#asignProcessToFolder${processId}`}
                                    routeFaq=''
                                    edit={`${t('words.rename')}`}
                                    dataBsTargetEdit={`#edit${processId}`}
                                    publish={!folder ? `${t('navigation.markAsFinal')}` : ''}
                                    dataBsTargetPublish={`#publish${processId}`}
                                    onClick={handleDropdownClick}
                                    action7={t('words.delete')}
                                    dataBsTargetAction7={`#generic${processId}`}
                                />
                            }
                        </div>
                        {(description?.length > 0 && !folder) &&
                            <p className='text-control overflow-hiden fs-6 w-auto text-white custom-description-height ' style={{ height: elementType !== "popular" ? 95 : "100%" }} > {slice(description, descriptionSize())}</p>
                        }
                        {thisProcessHasAOwner &&
                            <div className='d-flex w-100  bottom-0 '>
                                <div className='text-primary'>{owner}</div>
                                {(index === firstindex) &&
                                    <div className='ms-2'>
                                        <span className='badge rounded-pill text-theme bg-theme-transparent'>New</span>
                                    </div>
                                }
                            </div>
                        }


                    </div>
                </div >
            </div >

            {(processName && processId) &&
                <AssignProcessToFolderModal
                    processId={processId}
                    processName={processName}
                />}
            {(processName && processId) &&
                <EditprocessModal
                    folder={folder}
                    processId={processId}
                    processName={processName}
                    description={description}
                    setProject={setProject}
                    setProcess={setProcess}
                />}
            {(processId) &&
                <>
                    <GenericModal
                        title={t('words.delete')}
                        actionButton={t('words.delete')}
                        action={deleteProcess}
                        sentence={t('views.processView.areYouSure')}
                        processId={processId}
                    />
                    <PublishModal processId={processId} />
                </>
            }


        </>
    )
}
export default CardProcess