// Reac Component
import React from "react";
import { useNavigate } from "react-router-dom";

// App Component
import { Routes } from "../../routes";
import { BreadCrumb, InputForm, SearchInput, Spiner } from "../../components";
import { FamlaServices } from "../../services";

// Features
import UsersList from "./usersList";
import { logout, useAppDispatch, useAppSelector } from "../../stores";

//interface
import { iAlert } from "../../interfaces";
import { iUser } from "../../components/type";

//utils
import useSearch from "../../utils/useSearch";
import { useTranslation } from "react-i18next";

type iState = {
    email: string,
    isSubmit: boolean,

}

const UsersPages = () => {
    const defaultProjectId: string = "b15abbcd-4b91-407a-acf4-58eb7fafbdea";
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const auth = useAppSelector(state => state.auth);
    const [users, setUsers] = React.useState<iUser[]>([]);
    const companyId = auth.session.company_id;
    const [loading, setLoading] = React.useState(false);
    // const [filter, setFilter] = React.useState<string | undefined>('USER');
    const [alert, setAlert] = React.useState<iAlert>({
        isActive: false,
        type: '',
        message: ''
    });

    const [state, setState] = React.useState<iState>({
        email: "",
        isSubmit: false,
    })

    const [pagination, setPagination] = React.useState({
        total: 0,
        per_page: 10,
        last_page: 0,
        current_page: 1,
    });

    const { searchQuery, setSearchQuery, searchResults, searchLoad } = useSearch(`/users/search/my/user`, companyId);

    const logOut = () => {
        dispatch(logout());

        navigate(`${Routes.AUTH_LOGIN}?status=10`);
    };


    async function loadUsers(currentPage: number, per_page: number) {
        setLoading(true);

        try {
            setAlert({ isActive: false, type: '', message: '' });
            let data = await FamlaServices("api_host", `/users/company/${companyId}?page=${currentPage}&limit=${per_page}`, "GET", "", auth.access_token);

            if (data.status === 200) {
                const paginage = data.body.paginage;

                const workersWithRole = data.body.data.worker.map((user: iUser) => ({ ...user, role: "worker" }));
                const ownersWithRole = data.body.data.owner.map((user: iUser) => ({ ...user, role: "owner" }));
                const adminsWithRole = data.body.data.admin.map((user: iUser) => ({ ...user, role: "admin" }));

                let users: iUser[] = [
                    ...workersWithRole,
                    ...ownersWithRole,
                    ...adminsWithRole,
                ];

                const usersWithTokens = await Promise.all(
                    users.map(async (user: iUser) => {
                        try {
                            const tokenData = await FamlaServices("api_host", `/request/total_tokens/user?company_id=${companyId}&user_id=${user._id}`, "GET", "", auth.access_token);
                            if (tokenData.status === 200) {
                                return { ...user, total_tokens: tokenData.body.data.total_tokens || 0 };
                            } else {
                                return { ...user, total_tokens: 0 };
                            }
                        } catch (error) {
                            return { ...user, total_tokens: 0 };
                        }
                    })
                );

                setUsers(usersWithTokens);

                setPagination({
                    ...pagination,
                    current_page: paginage.current_page,
                    total: paginage.total,
                    last_page: paginage.last_page
                });
            } else if (data.status === 401 && data.body.description === "You do not have authorisation to continue") {
                setAlert({ isActive: true, type: 'warning', message: 'You do not have authorisation to continue' });
            } else if (data.status === 401) {
                logOut();
            } else {
                setAlert({ isActive: true, type: 'danger', message: 'An error has occurred, please try again later' });
            }
        } catch (e) {
            if (e instanceof Error) {
                console.error(e.message);
            }
        } finally {
            setLoading(false);
        }
    }



    React.useEffect(() => {
        loadUsers(pagination.current_page, pagination.per_page);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth.access_token]);



    const handleNextPage = () => {
        if (pagination.current_page < pagination.last_page) {
            setPagination((prev) => ({ ...prev, current_page: prev.current_page + 1 }));
            loadUsers(pagination.current_page + 1, pagination.per_page);
        }
    };

    const handlePrevPage = () => {
        if (pagination.current_page > 1) {
            setPagination((prev) => ({ ...prev, current_page: prev.current_page - 1 }));
            loadUsers(pagination.current_page - 1, pagination.per_page);
        }
    };

    const breadCrumb = [
        {
            name: t("dashboard.dashboard"),
            link: Routes.DASHBOARDS,
            isActive: false
        },
        {
            name: t("dashboard.users"),
            link: Routes.USERS_LIST,
            isActive: false
        },
        {
            name: t("dashboard.list"),
            link: "",
            isActive: true
        }
    ];

    // const filterOption = [
    //     { label: "USER", value: "USER" },
    // ]

    const deleteUser = async (userId?: string) => {
        let data = await FamlaServices("api_host", `/users/remove/${userId}`, "PUT", "", auth.access_token);
        if (data.status === 200) {
            // setAlert({ ...alert, type: "success", message: t('navigation.userSuccessfullyDeleted') })
            setUsers(users.filter((user: iUser) => user._id !== userId));


        }
    }
    const handleChange = (event: any) => {
        const { type, name, value, checked } = event.target;
        let fieldValue = type === 'checkbox' ? checked : value;

        if (type === 'number') {
            fieldValue = Number(fieldValue);
        }
        if (name === 'email') {
            setState((prev) => ({ ...prev, email: fieldValue }))
        }
    };

    const handleSubmit = async (event: React.SyntheticEvent) => {
        event.preventDefault();

        if (state.email.length === 0) {
            setAlert({ isActive: true, type: 'danger', message: t('navigation.enterValideEmail') });
            return;
        }

        const formData = {
            project_id: defaultProjectId,
            mail: [state.email],
        };

        setState((prevState) => ({ ...prevState, isSubmit: true }));

        try {
            const data = await FamlaServices("api_host", `/project/add_owner/`, "PUT", formData, auth.access_token);

            if (data.status === 200) {
                setAlert({ isActive: true, type: 'success', message: t('navigation.invitationSentSuccessfully') });
                setState((prevState) => ({ ...prevState, email: "" }));
                setTimeout(() => {
                    setAlert({ isActive: false, type: '', message: "" });
                }, 3000);
            } else if (data.status === 404) {
                setAlert({ isActive: true, type: 'danger', message: t('navigation.proJectNotFound') });
            } else if (data.status === 401) {
                setAlert({ isActive: true, type: 'danger', message: t("navigation.youAreNotLoggedIn") });
            } else {
                setAlert({ isActive: true, type: 'danger', message: t("navigation.anErrorOcured") });
            }
        } catch (error) {
            console.error("An error occurred:", error);
            setAlert({ isActive: true, type: 'danger', message: t("navigation.anErrorOcured") });
        } finally {
            setState((prevState) => ({ ...prevState, isSubmit: false }));
        }
    };

    return (
        <>
            <div className="container-fluid p-4">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                        <BreadCrumb name={t("dashboard.users")} links={breadCrumb} children={<></>} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="card rounded-3 bg-dark border-secondary">

                            <div className="p-4 row justify-content-between">
                                {/* <SelectForm
                                    selectClass=" px-1 py-1 m-0 h-100 ms-2"
                                    placeholder={'filter'}
                                    selectName='selectName'
                                    selectOptions={filterOption ?? []}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFilter(event.target.value)}
                                /> */}
                                <form className="col-12 col-md-6 col-lg-6 col-xl-3 col-sm-12 d-flex align-items-center">
                                    <SearchInput
                                        inputType="text"
                                        inputValue={searchQuery}
                                        placeholderValue={`${t('words.name')}`}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(event.target.value)}
                                        inputContainerClass="w-100"
                                        inputStyle={{ borderRadius: 20 }}
                                        inputClass="bg-dark text-light border-0"
                                        iconClass="fa-solid fa-magnifying-glass "
                                        iconName=""
                                        formClass='rounded-2  text-light text-bg-dark bg-dark border border-secondary'
                                        iconContainer='bg-dark text-secondary border-0'
                                    />
                                </form>
                                <div className=" col-12 col-md-6 col-lg-6 col-xl-3 col-sm-12">
                                    <button className=" btn btn-primary btn-xs  mt-3 mt-md-0 w-100" data-bs-target='#invitation' data-bs-toggle="modal"> {t('dashboard.addNewUser')}</button>
                                </div>
                            </div>
                            {/* {alert.isActive &&
                                <div className={`alert alert-${alert.type} mb-0 col-12 col-md-6 col-lg-6 offset-lg-3 my-2`} role="alert">
                                    {alert.message}
                                </div>
                            } */}

                            <div className="table-responsive border-0">
                                {searchQuery === "" ?
                                    <UsersList
                                        loading={loading}
                                        featuresDatas={users}
                                        context="user"
                                        key={1}
                                        listType="USERS"
                                        deleteCompany={deleteUser}
                                        setUsers={setUsers}
                                    />
                                    :
                                    <UsersList
                                        loading={!searchLoad}
                                        featuresDatas={searchResults}
                                        context="user"
                                        key={2}
                                        listType="USERS"
                                        deleteCompany={deleteUser}
                                        setUsers={setUsers}
                                    />
                                }
                            </div>
                            <div className="card-footer border-top-0 bg-dark">
                                <nav aria-label="Page navigation example">
                                    <ul className="pagination mb-0 justify-content-end">
                                        <li className={`page-item ${pagination.current_page === 1 ? 'disabled' : ''}`}>
                                            <button className="page-link mx-1 rounded" onClick={handlePrevPage}>
                                                <i className="fa-solid fa-chevron-left"></i>
                                            </button>
                                        </li>
                                        <li className={`page-item cursor-pointer  ${pagination.current_page === 1 ? 'active' : ''}`} onClick={() => { loadUsers(1, pagination.per_page) }}>
                                            <button className="page-link mx-1 rounded " >1</button>
                                        </li>
                                        {pagination.last_page >= 2 &&
                                            <li className={`page-item cursor-pointer  ${pagination.current_page === 2 ? 'active' : ''}`} onClick={() => { loadUsers(2, pagination.per_page) }}>
                                                <button className="page-link mx-1 rounded " >2</button>
                                            </li>}
                                        {pagination.last_page >= 3 &&
                                            <li className={`page-item cursor-pointer  ${pagination.current_page === 3 ? 'active' : ''}`} onClick={() => { loadUsers(3, pagination.per_page) }}>
                                                <button className="page-link mx-1 rounded " >3</button>
                                            </li>
                                        }
                                        <li className={`page-item ${pagination.current_page >= pagination.last_page ? 'disabled' : ''}`}>
                                            <button className="page-link mx-1 rounded bg-transparent" onClick={handleNextPage} disabled={pagination.current_page >= pagination.last_page}>
                                                <i className="fa-solid fa-chevron-right text-secondary"></i>
                                            </button>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div >
            </div >

            <div className="modal" id='invitation' tabIndex={-1}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content bg-dark ">
                        <div className="modal-header border-0">
                            <h5 className="modal-title text-white">{t('navigation.inviteOwner')}</h5>
                            <button type="button" className="btn-close bg-white" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {alert.isActive &&
                                <div className={`alert alert-${alert.type}`} role="alert">
                                    {alert.message}
                                </div>
                            }
                            <InputForm
                                inputType="email"
                                inputLabel="Email"
                                inputName="email"
                                inputValue={state.email}
                                isFloatingLabel={true}
                                labelClass="text-theme"
                                onChange={(e) => handleChange(e)}
                                inputContainerClass={'mb-3 p-0'}
                                inputClass="bg-dark text-light text-bg-dark"
                                inputStyle={{ height: 0, padding: 0 }}
                            />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{t('words.close')}</button>
                            <button type="button" onClick={handleSubmit} className="btn btn-primary">
                                {state.isSubmit ? <Spiner className="spinner-border-sm" />
                                    :
                                    <>{t('words.invite')}</>
                                }
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>


    )
};

export default UsersPages;