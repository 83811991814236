import React, { useEffect, useRef, useState } from 'react';
import { Undo2, Redo2, Square, Diamond, PlayCircle, StopCircle, ZoomIn, ZoomOut } from 'lucide-react';
import { SwimlaneHeaders } from './lib/SwimlaneHeaders';
import { SwimlaneData, Block, ProcessGroup, TextProperties } from './lib/types';
import { STYLES } from './lib/styles/constants';
import { useExportHandlers } from "./hooks/useExportHandlers"

// Initial swimlane data
// const initialSwimlaneData: SwimlaneData = {
//   id: "main",
//   type: "$swimlane",
//   height: 600,
//   width: 500,
//   header: {
//     text: "Customer Onboarding Process"
//   },
//   subHeaderRows: {
//     headers: ["Sales Manager", "Client", "Ops Manager"]
//   },
//   lines: [
//     {
//       id: "line1",
//       type: "line",
//       from: "sendProposal",
//       to: "discussProposal",
//       fromSide: "right",
//       toSide: "left"
//     },
//     {
//       id: "line2",
//       type: "line",
//       from: "discussProposal",
//       to: "negotiateTerms",
//       fromSide: "bottom",
//       toSide: "top"
//     },
//     {
//       id: "line3",
//       type: "line",
//       from: "negotiateTerms",
//       to: "evaluateDecision",
//       fromSide: "right",
//       toSide: "left"
//     },
//     {
//       id: "line4",
//       type: "line",
//       from: "evaluateDecision",
//       to: "signDeal",
//       fromSide: "bottom",
//       toSide: "top"
//     }
//   ]
// };

// Initial process groups
// const initialProcessGroups: ProcessGroup[] = [
//   {
//     id: 1,
//     type: "$sgroup",
//     groupChildren: ["sendProposal", "discussProposal"]
//   },
//   {
//     id: 2,
//     type: "$sgroup",
//     groupChildren: ["negotiateTerms", "evaluateDecision"]
//   },
//   {
//     id: 3,
//     type: "$sgroup",
//     groupChildren: ["signDeal"]
//   }
// ];

// Initial blocks
// const initialBlocks: Block[] = [
//   {
//     type: "process",
//     text: "Send Proposal",
//     x: 130,
//     y: 180,
//     id: "sendProposal"
//   },
//   {
//     type: "process",
//     text: "Discuss Proposal",
//     x: 340,
//     y: 180,
//     id: "discussProposal"
//   },
//   {
//     type: "process",
//     text: "Negotiate Terms",
//     x: 350,
//     y: 380,
//     id: "negotiateTerms"
//   },
//   {
//     type: "decision",
//     text: "Evaluate Decision",
//     x: 600,
//     y: 380,
//     id: "evaluateDecision"
//   },
//   {
//     type: "process",
//     text: "Sign the Deal",
//     x: 780,
//     y: 590,
//     id: "signDeal"
//   }
// ];

// const lines = initialSwimlaneData.lines
//   ? initialSwimlaneData.lines.map(line => ({
//     ...line
//   }))
//   : [];

// const swimData = [
//   {
//     ...initialSwimlaneData,
//     lines: undefined,
//   },
//   ...initialProcessGroups,
//   ...initialBlocks,
//   ...lines,
// ];

interface childprops {
  swimlaneData: any
}


function App(props: childprops) {
  const { swimlaneData } = props

  console.log(swimlaneData);

  const initialBlocks = JSON.parse(swimlaneData.swimlane);

  const initialProcessGroups = JSON.parse(swimlaneData.second);

  const initialSwimlaneData = JSON.parse(swimlaneData.line);
  console.log(initialSwimlaneData);

  const lines = initialSwimlaneData.lines
    ? initialSwimlaneData.lines.map(line => ({
      ...line
    }))
    : [];

  const swimData = [
    {
      ...initialSwimlaneData,
      lines: undefined,
    },
    ...initialProcessGroups,
    ...initialBlocks,
    ...lines,
  ];

  // References and states
  const containerRef = useRef<HTMLDivElement>(null);
  const swimlaneRef = useRef<SwimlaneHeaders | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [scale, setScale] = useState(1);
  const [currentData, setCurrentData] = useState(initialSwimlaneData);
  const [selectedBlock, setSelectedBlock] = useState<Block | null>(null);
  const [blocks, setBlocks] = useState<Block[]>(initialBlocks);

  // Initialize swimlane
  useEffect(() => {
    if (containerRef.current && !swimlaneRef.current) {
      swimlaneRef.current = new SwimlaneHeaders(
        'swimlane-container',
        currentData,
        blocks,
        initialProcessGroups
      );

      // Callback for data updates
      swimlaneRef.current.onSaveCallback((updatedData, updatedBlocks, updatedGroups) => {
        setCurrentData(updatedData);
        setBlocks(updatedBlocks);
      });

      // Callback for edit mode
      swimlaneRef.current.onEditModeChange((editMode) => {
        setIsEditing(editMode);
        if (!editMode) {
          setSelectedBlock(null);
        }
      });

      // Callback for history
      swimlaneRef.current.onHistoryChange((canUndoValue, canRedoValue) => {
        setCanUndo(canUndoValue);
        setCanRedo(canRedoValue);
      });

      // Callback for block selection
      swimlaneRef.current.onBlockSelect((block) => {
        setSelectedBlock(block);
      });

      swimlaneRef.current.render();
    }
  }, []);

  // Handle zoom
  const handleZoom = (direction: 'in' | 'out') => {
    const factor = direction === 'in' ? 1.2 : 0.8;
    const newScale = Math.min(Math.max(scale * factor, 0.5), 2);
    setScale(newScale);

    if (containerRef.current) {
      containerRef.current.style.transform = `scale(${newScale})`;
      containerRef.current.style.transformOrigin = 'top left';
    }
  };

  // Handle swimlane width change
  const handleWidthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newWidth = parseInt(e.target.value, 10);
    if (newWidth >= 800) {
      const updatedData = { ...currentData, width: newWidth };
      setCurrentData(updatedData);
      swimlaneRef.current?.updateData(updatedData);
    }
  };

  // Handle block property changes
  const handleBlockWidthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!selectedBlock) return;

    const newWidth = parseInt(e.target.value, 10);
    if (isNaN(newWidth) || newWidth < 50) return;

    const updatedBlock = { ...selectedBlock, width: newWidth };
    const updatedBlocks = blocks.map(block =>
      block.id === selectedBlock.id ? updatedBlock : block
    );

    setBlocks(updatedBlocks);
    setSelectedBlock(updatedBlock);
    swimlaneRef.current?.updateData(currentData, updatedBlocks, initialProcessGroups);
  };

  const handleBlockHeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!selectedBlock) return;

    const newHeight = parseInt(e.target.value, 10);
    if (isNaN(newHeight) || newHeight < 30) return;

    const updatedBlock = { ...selectedBlock, height: newHeight };
    const updatedBlocks = blocks.map(block =>
      block.id === selectedBlock.id ? updatedBlock : block
    );

    setBlocks(updatedBlocks);
    setSelectedBlock(updatedBlock);
    swimlaneRef.current?.updateData(currentData, updatedBlocks, initialProcessGroups);
  };

  const handleBlockFillChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!selectedBlock) return;

    const updatedBlock = { ...selectedBlock, fill: e.target.value };
    const updatedBlocks = blocks.map(block =>
      block.id === selectedBlock.id ? updatedBlock : block
    );

    setBlocks(updatedBlocks);
    setSelectedBlock(updatedBlock);
    swimlaneRef.current?.updateData(currentData, updatedBlocks, initialProcessGroups);
  };

  // Handle text style changes
  const handleTextStyleChange = (property: keyof TextProperties, value: any) => {
    if (!selectedBlock) return;

    const updatedBlock = {
      ...selectedBlock,
      textStyle: {
        ...selectedBlock.textStyle,
        [property]: value
      }
    };

    const updatedBlocks = blocks.map(block =>
      block.id === selectedBlock.id ? updatedBlock : block
    );

    setBlocks(updatedBlocks);
    setSelectedBlock(updatedBlock);
    swimlaneRef.current?.updateData(currentData, updatedBlocks, initialProcessGroups);
  };

  // Render shape buttons
  const renderShapeButton = (type: Block['type'], Icon: any, color: string) => {
    const colorClasses = {
      blue: 'bg-blue-50 text-blue-600 hover:bg-blue-100',
      purple: 'bg-purple-50 text-purple-600 hover:bg-purple-100',
      green: 'bg-green-50 text-green-600 hover:bg-green-100',
      red: 'bg-red-50 text-red-600 hover:bg-red-100'
    };

    return (
      <button
        draggable
        onDragStart={(e) => {
          e.dataTransfer.setData('shape-type', type);
        }}
        className={`flex flex-col items-center justify-center p-3 rounded-lg transition-colors ${colorClasses[color]}`}
      >
        <Icon className="w-6 h-6 mb-1" />
        <span className="text-xs capitalize">{type}</span>
      </button>
    );
  };

  // Handle drag and drop
  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    const shapeType = e.dataTransfer.getData('shape-type') as Block['type'];
    if (!shapeType) return;

    const container = containerRef.current;
    if (!container) return;

    const rect = container.getBoundingClientRect();
    const x = (e.clientX - rect.left) / scale;
    const y = (e.clientY - rect.top) / scale;

    const newBlock: Block = {
      id: `block-${Date.now()}`,
      type: shapeType,
      text: `New ${shapeType}`,
      x,
      y
    };

    const updatedBlocks = [...blocks, newBlock];
    if (initialProcessGroups.length > 0) {
      initialProcessGroups[0].groupChildren.push(newBlock.id);
    }

    setBlocks(updatedBlocks);
    swimlaneRef.current?.updateData(currentData, updatedBlocks, initialProcessGroups);
  };

  // Render block properties
  const renderBlockProperties = () => {
    if (!selectedBlock) return null;

    return (
      <div className="space-y-4">
        <h3 className="text-sm font-medium text-gray-600">Block Properties</h3>

        {/* Width */}
        <div>
          <label htmlFor="blockWidth" className="block text-xs text-gray-500 mb-1">
            Width
          </label>
          <div className="flex items-center gap-2">
            <input
              type="number"
              id="blockWidth"
              value={selectedBlock.width || getDefaultWidth(selectedBlock.type)}
              onChange={handleBlockWidthChange}
              min="50"
              step="10"
              className="w-full px-3 py-2 border border-gray-300 rounded text-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
            />
            <span className="text-sm text-gray-500">px</span>
          </div>
        </div>

        {/* Height */}
        <div>
          <label htmlFor="blockHeight" className="block text-xs text-gray-500 mb-1">
            Height
          </label>
          <div className="flex items-center gap-2">
            <input
              type="number"
              id="blockHeight"
              value={selectedBlock.height || getDefaultHeight(selectedBlock.type)}
              onChange={handleBlockHeightChange}
              min="30"
              step="10"
              className="w-full px-3 py-2 border border-gray-300 rounded text-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
            />
            <span className="text-sm text-gray-500">px</span>
          </div>
        </div>

        {/* Fill Color */}
        <div>
          <label htmlFor="blockFill" className="block text-xs text-gray-500 mb-1">
            Fill Color
          </label>
          <div className="flex items-center gap-2">
            <input
              type="text"
              id="blockFill"
              value={selectedBlock.fill || '#ffffff'}
              onChange={handleBlockFillChange}
              placeholder="#ffffff"
              className="w-full px-3 py-2 border border-gray-300 rounded text-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
            />
            <div
              className="w-8 h-8 border border-gray-300 rounded"
              style={{ backgroundColor: selectedBlock.fill || '#ffffff' }}
            />
          </div>
        </div>
      </div>
    );
  };

  // Render text properties
  const renderTextProperties = () => {
    if (!selectedBlock) return null;

    const textStyle = selectedBlock.textStyle || {};

    return (
      <div className="space-y-4 mt-6">
        <h3 className="text-sm font-medium text-gray-600">Text Properties</h3>

        {/* Font Size */}
        <div>
          <label htmlFor="fontSize" className="block text-xs text-gray-500 mb-1">
            Font Size
          </label>
          <div className="flex items-center gap-2">
            <input
              type="number"
              id="fontSize"
              value={textStyle.fontSize || 14}
              onChange={(e) => handleTextStyleChange('fontSize', parseInt(e.target.value, 10))}
              min="8"
              max="72"
              className="w-full px-3 py-2 border border-gray-300 rounded text-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
            />
            <span className="text-sm text-gray-500">px</span>
          </div>
        </div>

        {/* Font Weight */}
        <div>
          <label htmlFor="fontWeight" className="block text-xs text-gray-500 mb-1">
            Font Weight
          </label>
          <select
            id="fontWeight"
            value={textStyle.fontWeight || 'normal'}
            onChange={(e) => handleTextStyleChange('fontWeight', e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded text-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
          >
            <option value="normal">Normal</option>
            <option value="bold">Bold</option>
            <option value="light">Light</option>
          </select>
        </div>

        {/* Text Color */}
        <div>
          <label htmlFor="textColor" className="block text-xs text-gray-500 mb-1">
            Text Color
          </label>
          <input
            type="text"
            id="textColor"
            value={textStyle.textColor || '#333333'}
            onChange={(e) => handleTextStyleChange('textColor', e.target.value)}
            placeholder="#333333"
            className="w-full px-3 py-2 border border-gray-300 rounded text-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
          />
        </div>

        {/* Text Align */}
        <div>
          <label htmlFor="textAlign" className="block text-xs text-gray-500 mb-1">
            Text Align
          </label>
          <select
            id="textAlign"
            value={textStyle.textAlign || 'center'}
            onChange={(e) => handleTextStyleChange('textAlign', e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded text-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
          >
            <option value="left">Left</option>
            <option value="center">Center</option>
            <option value="right">Right</option>
          </select>
        </div>

        {/* Text Transform */}
        <div>
          <label htmlFor="textTransform" className="block text-xs text-gray-500 mb-1">
            Text Transform
          </label>
          <select
            id="textTransform"
            value={textStyle.textTransform || 'none'}
            onChange={(e) => handleTextStyleChange('textTransform', e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded text-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
          >
            <option value="none">None</option>
            <option value="uppercase">Uppercase</option>
            <option value="lowercase">Lowercase</option>
            <option value="capitalize">Capitalize</option>
          </select>
        </div>
      </div>
    );
  };

  // Get default dimensions
  const getDefaultWidth = (blockType: Block['type']) => {
    switch (blockType) {
      case 'process':
        return STYLES.process.width;
      case 'decision':
        return STYLES.decision.width;
      case 'start':
      case 'end':
        return STYLES.startEnd.width;
      default:
        return STYLES.process.width;
    }
  };

  const getDefaultHeight = (blockType: Block['type']) => {
    switch (blockType) {
      case 'process':
        return STYLES.process.height;
      case 'decision':
        return STYLES.decision.height;
      case 'start':
      case 'end':
        return STYLES.startEnd.height;
      default:
        return STYLES.process.height;
    }
  };

  const {
    isExporting,
    handleExportJson,
    handleExportPng,
    handleExportPdf
  } = useExportHandlers(containerRef, swimData);

  function toggleDropdown(): void {
    const dropdown = document.getElementById('dropdownMenu');
    dropdown?.classList.toggle('hidden');
  }

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Left Sidebar - Only visible in edit mode */}
      {isEditing && (
        <div
          className="fixed left-0 top-0 h-full bg-white shadow-lg w-64 transition-all duration-300"
          style={{ zIndex: 50 }}
        >
          <div className="p-4">
            <h2 className="text-lg font-semibold mb-4">Tools</h2>

            {/* Shapes Section */}
            <div className="mb-6">
              <h3 className="text-sm font-medium text-gray-600 mb-2">Shapes</h3>
              <div className="grid grid-cols-2 gap-2">
                {renderShapeButton('process', Square, 'blue')}
                {renderShapeButton('decision', Diamond, 'purple')}
                {renderShapeButton('start', PlayCircle, 'green')}
                {renderShapeButton('end', StopCircle, 'red')}
              </div>
            </div>

            {/* History Section */}
            <div className="space-y-2">
              <h3 className="text-sm font-medium text-gray-600 mb-2">History</h3>
              <button
                onClick={() => swimlaneRef.current?.undo()}
                disabled={!canUndo}
                className={`w-full px-4 py-2 rounded flex items-center gap-2 ${canUndo
                  ? 'bg-blue-50 text-blue-600 hover:bg-blue-100'
                  : 'bg-gray-100 text-gray-400 cursor-not-allowed'
                  }`}
              >
                <Undo2 size={16} />
                Undo
              </button>
              <button
                onClick={() => swimlaneRef.current?.redo()}
                disabled={!canRedo}
                className={`w-full px-4 py-2 rounded flex items-center gap-2 ${canRedo
                  ? 'bg-blue-50 text-blue-600 hover:bg-blue-100'
                  : 'bg-gray-100 text-gray-400 cursor-not-allowed'
                  }`}
              >
                <Redo2 size={16} />
                Redo
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Right Sidebar - Only visible in edit mode */}
      {isEditing && (
        <div
          className="fixed right-0 top-0 h-full bg-white shadow-lg w-64 transition-all duration-300"
          style={{ zIndex: 50 }}
        >
          <div className="p-4">
            <h2 className="text-lg font-semibold mb-4">Properties</h2>

            {/* Width Control */}
            <div className="mb-6">
              <h3 className="text-sm font-medium text-gray-600 mb-2">Swimlane Width</h3>
              <div className="flex items-center gap-2">
                <input
                  type="number"
                  id="width"
                  value={currentData.width}
                  onChange={handleWidthChange}
                  min="800"
                  step="50"
                  className="w-full px-3 py-2 border border-gray-300 rounded text-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                />
                <span className="text-sm text-gray-500">px</span>
              </div>
            </div>

            {/* Block Properties */}
            {renderBlockProperties()}

            {/* Text Properties */}
            {renderTextProperties()}
          </div>
        </div>
      )}

      {/* Main Content */}
      <div
        className={`flex-1 flex flex-col transition-all duration-300`}
        style={{
          marginLeft: isEditing ? '16rem' : '0',
          marginRight: isEditing ? '16rem' : '0'
        }}
      >
        {/* Top Bar */}
        <div
          className="fixed top-0 bg-white shadow-sm z-40 transition-all duration-300"
          style={{
            left: isEditing ? '16rem' : '0',
            right: isEditing ? '16rem' : '0'
          }}
        >
          <div className="max-w-full px-4 py-3 flex items-center justify-between">
            <h1 className="text-xl font-semibold text-gray-800">Swimlane Editor</h1>
            <div className="flex items-center gap-4">
              {/* Zoom Controls */}
              <div className="flex items-center gap-2">
                <button
                  onClick={() => handleZoom('out')}
                  className="p-2 rounded hover:bg-gray-100"
                  disabled={scale <= 0.5}
                >
                  <ZoomOut className="w-5 h-5 text-gray-600" />
                </button>
                <span className="text-sm text-gray-600">{Math.round(scale * 100)}%</span>
                <button
                  onClick={() => handleZoom('in')}
                  className="p-2 rounded hover:bg-gray-100"
                  disabled={scale >= 2}
                >
                  <ZoomIn className="w-5 h-5 text-gray-600" />
                </button>
              </div>

              <div className="relative inline-block text-left">

                <button
                  id="dropdownButton"
                  className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  type="button"
                  onClick={() => toggleDropdown()}
                >
                  Export
                  <svg
                    className="-mr-1 ml-2 h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </button>


                <div
                  id="dropdownMenu"
                  className="hidden origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="dropdownButton">
                    <a
                      href="#"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                      role="menuitem"
                      onClick={() => handleExportPdf()}
                    >
                      Export to PDF
                    </a>
                    <a
                      href="#"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                      role="menuitem"
                      onClick={() => handleExportPng()}
                    >
                      Export to PNG
                    </a>
                    <a
                      href="#"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                      role="menuitem"
                      onClick={() => handleExportJson()}
                    >
                      Export to JSON
                    </a>

                  </div>
                </div>
              </div>

              <button
                onClick={() => swimlaneRef.current?.toggleEditMode()}
                className={`px-4 py-2 rounded-lg transition-colors ${isEditing
                  ? 'bg-green-500 hover:bg-green-600 text-white'
                  : 'bg-blue-500 hover:bg-blue-600 text-white'
                  }`}
              >
                {isEditing ? 'Finish Editing' : 'Edit'}
              </button>
            </div>
          </div>
        </div>

        {/* Swimlane Container */}
        <div className="flex-1 p-6 overflow-hidden mt-14 w-screen">
          <div
            className="w-full h-full overflow-auto pb-3"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            <div
              id="swimlane-container"
              ref={containerRef}
              className="bg-white rounded-lg shadow-md inline-block min-w-full "
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;